
import { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = ({location}) => {
	useEffect(() => {
		navigate("/")
	}, [])
	return null
}

export default NotFoundPage